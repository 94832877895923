import React from 'react';

const DownloadTemplate = (props) => {
    React.useEffect(() => {
        const handleDownload = async () => {
            try {
                const blob = new Blob([props.pageContext.file], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = props.pageContext.fileName;
                link.click();
                setTimeout(() => window.close());
            } catch (error) {
                console.error('Download failed:', error);
            }
        };
        handleDownload();
    }, [props.pageContext.file]);
    return (
        <></>
    );
};

export default DownloadTemplate;